import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useSiteMetadata } from "./hooks/useSiteMetadata"
import { Link } from "gatsby"

const Bio = () => {
  const { author, social } = useSiteMetadata()

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
        <p>
          Written by <strong>{author.name}</strong> <br />
          {author?.summary || null}
          <br />
          <Link to="/about" className="content-link" alt="About Guide Projects">About Guide Projects</Link> ||{" "}
          <Link to="/tags" className="content-link" alt="Topics">Topics</Link> ||{" "}
          <Link to="/glossary" className="content-link" alt="Glossary">Glossary</Link> ||{" "}
          <a href={`https://reddit.com/r/${social?.reddit || ``}`} className="content-link" alt="Reddit: Guide Projects">Reddit</a> ||{" "}
          <a href={`https://github.com/${social?.github || ``}`} className="content-link" alt="Github: Guide Projects">Github</a>
        </p>
      )}
    </div>
  )
}

export default Bio
