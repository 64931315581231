import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ListHeader = ({ catalog }) => {
  return (
    <div className="listHeader">
      <StaticImage
        className="listHeader-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
        display="inline"
      />
      <span className="catalog-header">
        <p>{catalog}</p>
      </span>
    </div>
  )
}

export default ListHeader
