import * as React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import ListHeader from "../components/listHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"


import "../style.css"

// found this function at www.geeksforgeeks.org/how-to-get-the-standard-deviation-of-an-array-of-numbers-using-javascript/
function dev(arr) {
  // Creating the mean with Array.reduce
  let mean =
    arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length
  // Assigning (value - mean) ^ 2 to every array item
  arr = arr.map(k => {
    return (k - mean) ** 2
  })
  // Calculating the sum of updated array
  let sum = arr.reduce((acc, curr) => acc + curr, 0)
  // Calculating the variance
  let variance = sum / arr.length
  // Returning the Standered deviation
  return Math.sqrt(variance)
}

// This function groups and labels value sets. Your solution need not
// be as verbose, but I like how easy this code is to understand.
// value is the number of occurnances of a particular tag
// min is the minimum number of occurances of any/all tags (probably 1)
// max is the number of occurances of the most common tag
// std is the standard deviation, which is calculated in the above function
const rangeLabel = (value, min, mean, max, std) => {
  // normal distribution
  let lowest = min + std
  let lower = mean - std
  let upper = mean + std
  let highest = max - std
  // greater than and less than comparisons
  if (value <= lowest) return "lowest"
  if (value < lower && value > lowest) return "lower"
  if (lower < value && value <= upper) return "middle"
  if (upper <= value && value < highest) return "higher"
  if (value >= highest) return "highest"
}

const Tags = ({ data, location }) => {
  // Math stuff to assign an appropriate text size on a bell curve.
  // Limited to 5 font sizes to keep the page tidy.
  const tags = data.allTags.group
  const values = [...tags.map(t => t.totalCount)]
  const min = Math.min(...tags.map(t => t.totalCount))
  const max = Math.max(...tags.map(t => t.totalCount))
  const mean = values.reduce((a, b) => a + b, 0) / values.length
  const stDev = dev(values)

  const catalog = "Topics"

  // Assigns a font size to each value set by label
  function tagSize(rangeLabel) {
    switch (rangeLabel) {
      case "lowest":
        return "var(--fontSize-0)"
      case "lower":
        return "var(--fontSize-1)"
      case "middle":
        return "var(--fontSize-3)"
      case "higher":
        return "var(--fontSize-5)"
      case "highest":
        return "var(--fontSize-6)"
      default:
        return "var(--fontSize-0)"
    }
  }

  if (tags.length === 0) {
    return (
      <Layout location={location}>
        <Seo title="Topics" />
        <ListHeader catalog={catalog} />
        <p>No topics found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title="Topics" />
      <ListHeader catalog={catalog} />
      <p>
        Topics here are tagged in individual posts and projects. Text size
        increases according to the frequency of topic tag occurences. Select one
        to browse by topic.
      </p>
      <p
        style={{
          textAlign: "center",
          lineHeight: "2.3rem",
        }}
      >
        {tags.map((tag, index) => {
          const totalCount = tag.totalCount
          const fieldValue = tag.fieldValue.replace(/\s/g, "\u00A0")
          const linkValue = tag.fieldValue.replace(/\s/g, "%20")
          const label = rangeLabel(totalCount, min, mean, max, stDev)
          return (
            <span key={index}>
              <span
                style={{
                  fontSize: tagSize(label),
                }}
              >
                <Link
                  to={`/tags/${linkValue}`}
                  alt={fieldValue}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {fieldValue}
                </Link>
              </span>{" "}
            </span>
          )
        })}
      </p>

      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default Tags

export const tagsQuery = graphql`
  query {
    allTags: allMdx {
      totalCount
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`


